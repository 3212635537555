var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"350","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"white--text"},[_vm._v("Nueva entrada")])]),_c('v-card-text',[_c('v-select',{staticClass:"mt-3",attrs:{"rules":[
                        value => !!value || 'Required.',
                        ],"items":_vm.tipoInEg,"loading":_vm.loadingTipo,"item-text":"item","item-value":"id","label":"Tipo","rounded":"","outlined":"","dense":""},on:{"click":function($event){return _vm.cargaTipoIngEg(_vm.tipo)}},model:{value:(_vm.nuevo.item),callback:function ($$v) {_vm.$set(_vm.nuevo, "item", $$v)},expression:"nuevo.item"}}),(_vm.nuevo.item ==7 || _vm.nuevo.item ==17)?_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"Otros","dense":""},model:{value:(_vm.nuevo.itemOtro),callback:function ($$v) {_vm.$set(_vm.nuevo, "itemOtro", $$v)},expression:"nuevo.itemOtro"}}):_vm._e(),_c('v-select',{attrs:{"rules":[
                        value => !!value || 'Required.',
                        ],"items":_vm.horaList,"label":"Hora","rounded":"","outlined":"","dense":""},model:{value:(_vm.nuevo.hora),callback:function ($$v) {_vm.$set(_vm.nuevo, "hora", $$v)},expression:"nuevo.hora"}}),_c('v-expand-transition',[(_vm.nuevo.item==19 || _vm.nuevo.item==20)?_c('v-alert',{attrs:{"text":"","type":"info"}},[_vm._v(" Esta cantidad no afecta al balance ")]):_vm._e()],1),_c('v-text-field',{attrs:{"rounded":"","outlined":"","rules":[
                        value => value > 0 || 'Valor tiene que ser mayor a 0',
                        value => !!value || 'Required.',
                    ],"type":"number","label":"Cantidad (ml/hr)","dense":""},model:{value:(_vm.nuevo.cantidad),callback:function ($$v) {_vm.$set(_vm.nuevo, "cantidad", _vm._n($$v))},expression:"nuevo.cantidad"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"Observaciones","dense":""},model:{value:(_vm.nuevo.observaciones),callback:function ($$v) {_vm.$set(_vm.nuevo, "observaciones", $$v)},expression:"nuevo.observaciones"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.cancela}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.formValid,"loading":_vm.loadingGuarda},on:{"click":_vm.guarda}},[_vm._v("Guardar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }