<template>
    <v-dialog
        v-model="dialog"
        max-width="350"
        persistent
    >

        <v-card>
            <v-form v-model="formValid" ref="form">
                <v-card-title class="primary">
                    <span class="white--text">Nueva entrada</span>
                </v-card-title>

                <v-card-text>
                    
                    <v-select
                        class="mt-3"
                        :rules="[
                            value => !!value || 'Required.',
                            ]"
                        v-model="nuevo.item"
                        :items="tipoInEg"
                        :loading="loadingTipo"
                        item-text="item"
                        item-value="id"
                        label="Tipo"
                        rounded
                        outlined
                        dense
                        @click="cargaTipoIngEg(tipo)"
                    ></v-select>

                    

                    <v-text-field
                        v-if="nuevo.item ==7 || nuevo.item ==17"
                        v-model="nuevo.itemOtro"
                        rounded
                        outlined
                        label="Otros"
                        dense
                    ></v-text-field>

                    <v-select
                        :rules="[
                            value => !!value || 'Required.',
                            ]"
                        v-model="nuevo.hora"
                        :items="horaList"
                        label="Hora"
                        rounded
                        outlined
                        dense
                    ></v-select>

                    <v-expand-transition>
                        <v-alert text type="info" v-if="nuevo.item==19 || nuevo.item==20">
                            Esta cantidad no afecta al balance
                        </v-alert>
                    </v-expand-transition>
                    
                    <v-text-field
                        v-model.number="nuevo.cantidad"
                        rounded
                        outlined
                        :rules="[
                            value => value > 0 || 'Valor tiene que ser mayor a 0',
                            value => !!value || 'Required.',
                        ]"
                        type="number"
                        label="Cantidad (ml/hr)"
                        dense
                    ></v-text-field>

                    <v-text-field
                        v-model="nuevo.observaciones"
                        rounded
                        outlined
                        label="Observaciones"
                        dense
                    ></v-text-field>



                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="error" @click="cancela">Cancelar</v-btn>
                    <v-btn color="success" :disabled="!formValid" @click="guarda" :loading="loadingGuarda">Guardar</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        nuevaEntradaDialog:Boolean,
        turno:String,
        tipo:String,
        estaFecha:String,
        internamiento:Number,
    },
    data:()=>({
        formValid:false,
        nuevo:{},
        loadingGuarda:false,

        tipoInEg:[],
        loadingTipo:false,

    }),
    methods:{
        cancela(){
            this.$refs.form.reset()
            this.dialog = false
        },
        async cargaTipoIngEg(tipo){
            this.loadingTipo = true
            let ti = ''

            if (tipo == 'ingreso') {
                ti = 'i'
            }

            if (tipo == 'egreso') {
                ti = 'e'
            }
            try {
                let dat = await this.$http({
                    method: 'GET',
                    url:'/enfermeria/listIngEg',
                    params: {tipo:ti}
                })
                this.tipoInEg = dat.data
                this.loadingTipo = false

            }catch(err){
                this.todo = false
                console.log(err)
                this.loadingTipo = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async guarda(){
            this.loadingGuarda = true
            this.nuevo.cantidad = parseFloat(this.nuevo.cantidad)
            if (!this.nuevo.observaciones){
                this.nuevo.observaciones=this.moment(new Date()).format('DD-MM-YYYY hh:mm:ss')+' (manual)'
            }
            let prms = {...this.nuevo,
                turno: this.turno,
                fecha: this.estaFecha,
                internamiento: this.internamiento,
            }

            try{
                let liq = await this.$http({
                    method:'POST',
                    url: '/enfermeria/guardaInOut',
                    data: prms
                })
                this.loadingGuarda=false

                if (liq.data.estado == true){
                    this.$emit('agregado')
                    this.dialog = false
                } else {
                    this.loadingGuarda=false
                    console.log(liq.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })

                }

            }catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingGuarda=false
            }
        },
    },
    computed:{
        dialog:{
            get(){
                return this.nuevaEntradaDialog
            },
            set(val){
                this.$emit('update:nuevaEntradaDialog',val)
            },
        },
            horaList(){
                let hl=[]
                switch (this.turno){
                    case 'mat':
                        hl = ['08:00', '09:00','10:00','11:00','12:00','13:00','14:00']
                        break
                    case 'ves':
                        hl = ['15:00','16:00','17:00','18:00','19:00','20:00']
                        break
                    case 'noc':
                        hl = ['21:00','22:00','23:00','00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00',]
                        break

                    }
                return hl

            },
        
        
    },
    watch:{
        dialog(v){
            if (!v){
                this.nuevo = {}
                this.$refs.form.reset()
            }
        },
        
    },
}
</script>

<style>
</style>